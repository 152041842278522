<template>
    <div class="dashboard-view" v-if="getUser">
        <Header
            :title="`${greetingForm} ${getUser.getFirstName()}`"
            :subtitle="getDate">
        </Header>

        <div class="container">
            <div class="dashboard-up-next">
                <up-next-slider
                    :recipes="recipes" />
            </div>

            <div class="dashboard-recipes" v-if="day_menus">
                <week-menu-switcher
                    :planned-menus="planned_day_menus"
                    :day-menus="day_menus"
                    :relative-days="true"
                    @change-kcal="selectedKcal = $event"
                />
            </div>

            <div class="buttons">
                <button
                v-if="isMember"
                    class="btn -icon -gray"
                    @click="openPdfViewer()"
                >
                <iconDownload />
                    PDF
                    </button>
                    <pdf-viewer
                        v-if="pdfUrl"
                        :title="`${$t('Dashboard')} - PDF`"
                        :subtitle="`
                            ${selectedKcal}
                            ${$t('kcal')} - PDF
                        `"
                        :filename="`${$t('Dashboard')} - ${selectedKcal} ${$t('kcal')}`"
                        :url="pdfUrl"
                        @close="pdfUrl = null"
                />
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import DashboardApi from "@/lib/api/Dashboard";
import Header from "@/components/Header";
import UpNextSlider from "@/components/UpNextSlider";
import WeekMenuSwitcher from "@/components/WeekMenuSwitcher";
import PdfViewer from "@/components/PdfViewer";
import DateTime from "@/lib/misc/DateTime";

import iconDownload from "@/assets/icons/icon-download.svg?inline";

export default {
    name: "Dashboard",
    components: {
        Header,
        UpNextSlider,
        WeekMenuSwitcher,
        PdfViewer,
        iconDownload
    },
    data() {
        return {
            day_menus: null,
            planned_day_menus: null,
            recipes: null,
            greetingForm: "",
            pdfViewerOpen: false,
            pdfUrl: undefined,
            selectedKcal: 1800
        };
    },
    methods: {
        async openPdfViewer() {
            try {
                const arrayBuffer = await DashboardApi.getPdf(this.selectedKcal);

                if (!arrayBuffer) {
                    throw new Error("Failed to fetch PDF");
                }

                // Convert ArrayBuffer to a Blob
                const blob = new Blob([arrayBuffer], { type: "application/pdf" });

                // Create an Object URL
                this.pdfUrl = URL.createObjectURL(blob);

                console.log("PDF successfully loaded:", this.pdfUrl);
            } catch (error) {
                console.error("Error fetching PDF:", error);
            }
        }
    },
    computed: {
        ...mapGetters("user", ["getUser"]),
        ...mapState("user", ["isMember"]),
        getDate: () => DateTime.ConvertDateToHumanReadable(new Date())
    },
    async mounted() {
        let currentDate = new Date();
        let hours = currentDate.getHours();

        if (hours < 6) {
            this.greetingForm = this.$t("Good night");
        } else if (hours < 12) {
            this.greetingForm = this.$t("Good morning");
        } else if (hours < 18) {
            this.greetingForm = this.$t("Good afternoon");
        } else {
            this.greetingForm = this.$t("Good evening");
        }

        // ! PRO-526
        let dashboard = await DashboardApi.GetDashboard();

        if (!dashboard) {
            return;
        }

        this.day_menus = dashboard.day_menus;
        this.planned_day_menus = dashboard.planned_day_menus;

        // ! PRO-528
        this.recipes = dashboard.recipes;
    }
};
</script>

<style lang="scss">
@import "index";
</style>
